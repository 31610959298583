import React from "react"
import { Link } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from "../../components/SEO"

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
}

const Blog = () => {


    return (
        <Layout>
            <SEO title={"Blog"} />
            <div className="rbs-section">
                <div className="rbs-box-a">
                    <div className="content">
                        <h1 className="title-xl mb-16">
                            Blog
                        </h1>

                        <div className="border-b border-solid border-current"></div>
                        <Link to="/blog/branding-for-startups" className="block border-b border-solid border-current py-8">
                            <p className="title">Branding for Startups <span className="body-s uppercase mx-4 text-rbs-black-50 align-middle">2 min read</span></p>
                        </Link>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default Blog
